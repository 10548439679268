import React, { useEffect } from "react";
import Alert from '@amzn/meridian/alert';


const HomePage = (props) => {


    useEffect(() => {
    }, [])


    return (
        <>
          <br />
          <Alert
            type="success"
          >STEP website is progress!!!</Alert>

        </>
    );
};


export default HomePage