import React from 'react'
import AppLayout from "@amzn/meridian/app-layout";
import Header from "../components/header/Header";
import HomePage from "../components/home/HomePage";

const STEPWebsite = (props) => {

    return (
        <AppLayout
            headerComponent={Header}
        >
            <Header/>
            <HomePage/>
        </AppLayout>
    )
}

export default STEPWebsite