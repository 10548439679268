import HomePage from "./pages/HomePage";
import AppLayout from "@amzn/meridian/app-layout"
import Header from './components/header/Header';
const App = () => (
    <AppLayout headerComponent={Header} >
      <HomePage />
    </AppLayout>
);

export default App;
