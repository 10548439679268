import React from "react"
import Masthead, {MastheadTitle} from "@amzn/meridian/masthead"
import "../../css/header.css"


const Header = () => {

    return (
        <Masthead backgroundColor={"#232f3e"} >
            <MastheadTitle href="#">
                <h1 className={"header-text"}>StepWebsite</h1>
            </MastheadTitle>
            <div type="icon" size="small" className={"header-text"}>
                Welcome!
            </div>
        </Masthead>
    )
}

export default Header